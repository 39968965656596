import type { PropType } from 'vue'
import type { SortDirection } from '@tanstack/vue-table'
import { Icon } from '#components'

export default defineComponent({
  props: {
    sort: {
      type: [String, Boolean] as PropType<SortDirection | false>,
      required: true,
    },
  },
  setup(props) {
    return () => (
      <Icon
        class={'ml-1'}
        name={props.sort === 'asc' ? 'chevron-up' : 'chevron-down'}
      />
    )
  },
})
